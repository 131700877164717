import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    CircularProgressbarWithChildren,
    buildStyles
  } from 'react-circular-progressbar';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  }
}));

export default function CircularIndeterminate(props) {
  const classes = useStyles();
  let { color = 'secondary' } = props;
  return (
    <div className="full-loader" >
      {/* <CircularProgress /> */}
      {/* <CircularProgress color={color} /> */}
      <CircularProgress  strokeWidth={5} style={{width: '65px', height: '65px'}} />


    </div>
  );
}
