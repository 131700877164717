/**
 * Reducer for campaign
 */

import get from 'lodash/get';

import {
  CREATE_CAMPAIGN_ERROR,
  CREATE_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN_ERROR,
  DELETE_CAMPAIGN_SUCCESS,
  LOAD_CAMPAIGN_ERROR,
  LOAD_CAMPAIGN_SUCCESS,
  LOAD_CAMPAIGNS_ERROR,
  LOAD_CAMPAIGNS_SUCCESS,
  UPDATE_CAMPAIGN_ERROR,
  UPDATE_CAMPAIGN_SUCCESS,
  ADD_EMPTY_FIELD, REMOVE_EMPTY_FIELD
} from '../actions/actionTypes';

const initialState = {
  page: 1,
  totalPages: 1,
  limit: 10,
  data: [],
  isError: false,
  isSuccess: false,
  errorMessage: '',
  isLoading: false,
  hasUpdate: false
};

export const campaignSelector = (state) => get(state, 'campaigns', []);

const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CAMPAIGNS_SUCCESS:
      return { ...state, ...action.campaigns };
    case LOAD_CAMPAIGN_SUCCESS:
      return loadCampaignSuccessHandler(state, action);
    case CREATE_CAMPAIGN_SUCCESS:
      return { ...state, data: [...state.data, action.campaign] };
    case UPDATE_CAMPAIGN_SUCCESS:
      return { ...state, ...action.data };
    case DELETE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        data: state.data.filter(
          (campaign) => campaign._id !== action.campaign._id
        )
      };
    case ADD_EMPTY_FIELD:
      return addEmptyField(state, action);
    case REMOVE_EMPTY_FIELD:
      return removeEmptyField(state, action);
    case LOAD_CAMPAIGN_ERROR:
    case LOAD_CAMPAIGNS_ERROR:
    case UPDATE_CAMPAIGN_ERROR:
    case DELETE_CAMPAIGN_ERROR:
    case CREATE_CAMPAIGN_ERROR:
      return { errorMessage: action.errorMessage, isError: action.isError };
    default:
      return state;
  }
};

const loadCampaignSuccessHandler = (state, action) => {
  return { ...state, campaign: action.campaign };
};

const addEmptyField = (state, action) => {
  return {
    ...state,
    campaign: {
      ...state.campaign,
      fields: [...state.campaign.fields, action.data]
    }
  };
};

const removeEmptyField = (state, action) => {
  return {
    ...state,
    campaign: {
      ...state.campaign,
      fields: state.campaign.fields.splice(action.data, 0)
    }
  };
};

export default campaignReducer;
