import React from 'react';

const WrapperStyled = (props) => {
  const { sectionHeading, children, variant } = props;

  return (
    <div className={`${variant == 'alternative' ? 'wrapper-styled-alternative' : 'wrapper-styled'} mb-spacing-6`}>
      {sectionHeading && (
        <h5 className="display-4 mb-4 font-weight-bold">{sectionHeading}</h5>
      )}
      {children}
    </div>
  );
};

export default WrapperStyled;
