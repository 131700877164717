import get from 'lodash/get';
import {
  ADD_TEAM_MEMBER_ERROR,
  ADD_TEAM_MEMBER_SUCCESS,
  CREATE_TEAM_ERROR,
  CREATE_TEAM_SUCCESS,
  DELETE_TEAM_ERROR,
  DELETE_TEAM_MEMBER_ERROR,
  DELETE_TEAM_MEMBER_SUCCESS,
  DELETE_TEAM_SUCCESS,
  LOAD_TEAM_ERROR,
  LOAD_TEAM_SUCCESS,
  LOAD_TEAMS_ERROR,
  LOAD_TEAMS_SUCCESS,
  UPDATE_TEAM_ERROR,
  UPDATE_TEAM_MANAGER_ERROR,
  UPDATE_TEAM_MANAGER_SUCCESS,
  UPDATE_TEAM_MEMBER_ERROR,
  UPDATE_TEAM_MEMBER_SUCCESS,
  UPDATE_TEAM_SUCCESS,
  RESET_TEAM_REDUCER,
} from '../actions/actionTypes';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';

const initialState = {
  page: 1,
  totalPages: 1,
  limit: 10,
  data: [],
  isError: false,
  isSuccess: false,
  errorMessage: '',
  isLoading: false,
  hasUpdate: false
};

export const teamSelector = (state) => get(state, 'teams', []);

const teamReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TEAMS_SUCCESS:
      return { ...state, data: action.teams.teams };
    case LOAD_TEAM_SUCCESS:
      return loadTeamSuccessHandler(state, action);
    case DELETE_TEAM_SUCCESS:
      return {
        ...state,
        data: state.data.filter((team) => team._id !== action.team._id)
      };
    case CREATE_TEAM_SUCCESS:
      return { ...state, data: [...state.data, action.team] };
    case UPDATE_TEAM_SUCCESS:
      return { ...state, ...action.data };
    case ADD_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        data: state.data.map((el) =>
          el._id === action.data.team._id ? action.data.team : el
        ),
        hasUpdate: action.data.hasUpdate
      };
    case UPDATE_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        data: state.data.map((el) =>
          el._id === action.team._id ? action.team : el
        )
      };
    case DELETE_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        data: state.data.map((el) =>
          el._id === action.data.team._id ? action.data.team : el
        ),
        hasUpdate: action.data.hasUpdate
      };
    case UPDATE_TEAM_MANAGER_SUCCESS:
      return {
        ...state,
        data: state.data.map((el) =>
          el._id === action.data.team._id ? action.data.team : el
        )
      };
    case LOAD_TEAM_ERROR:
    case LOAD_TEAMS_ERROR:
    case UPDATE_TEAM_ERROR:
    case DELETE_TEAM_ERROR:
    case ADD_TEAM_MEMBER_ERROR:
    case UPDATE_TEAM_MEMBER_ERROR:
    case DELETE_TEAM_MEMBER_ERROR:
    case UPDATE_TEAM_MANAGER_ERROR:
    case CREATE_TEAM_ERROR:
      return { errorMessage: action.errorMessage, isError: action.isError };
    case RESET_TEAM_REDUCER:
      return initialState;
    default:
      return state;
  }
};

const loadTeamSuccessHandler = (state, action) => {
  const { data } = state;
  if (isEmpty(data)) {
    return { ...state, data: [...data, action.team] };
  } else {
    const isTeamExit = find(data, { _id: action.team && action.team._id });
    if (!isTeamExit) {
      return { ...state, data: [...data, action.team] };
    }
    return state;
  }
};

export default teamReducer;
