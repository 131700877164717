import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'Quick Action',
  initialState: {
    note: false,
    createAccount: false,
    linkAccount: false,
    bankAccount: false,
    kycReminder: false,
    changeLeverage: false,
    whatsappMsg:false
  },
  reducers: {
    toggleAction: (state, action) => {
      
      state[action.payload.name] = action.payload.value
    }
  }
})

export const { toggleAction } = slice.actions;
const reducer = slice.reducer;
export default reducer;