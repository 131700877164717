/**
 * Reduer for email templates
 */
import get from 'lodash/get';
import {
  FETCH_CLIENT_TRANSACTIONS,
  UPDATE_CLIENT_TRANSACTION,
  ADD_CLIENT_TRANSACTION,
  RESET_PAGE_CLIENT_TRANSACTIONS
} from '../actions/actionTypes';
import { updateItemInItems } from '../../utils';

const initialState = {
  transactions: [],
  isLoading: false,
  page:1,
  hasUpdate: false,
  totalPages:1
};

export const clientTransactionsSelector = (state) => get(state, 'clinetTransactions', []);
const clientTransactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CLIENT_TRANSACTIONS:
      return { ...state, ...action.data };
    case UPDATE_CLIENT_TRANSACTION:
      return updatedTransactions (state, action);
    case ADD_CLIENT_TRANSACTION:
      return addTransaction(state, action);
    case RESET_PAGE_CLIENT_TRANSACTIONS:
      return { ...initialState };
    default:
      return state;
  }
};

const addTransaction = (state, action) => {
  const { transaction, hasUpdate } = action.data;
  if (transaction) {
    return {
      ...state,
      transactions: [...state.transactions, transaction],
      hasUpdate
    };
  }
  return { ...state, ...action.data };
};

const updatedTransactions = (state, action) => {
  const { transaction } = action.data;
  if (transaction) {
   const {index,status} = transaction;
    return { ...state, transactions: updateItemInItems(state.transactions, index, {status}) };
  }
  return { ...state, ...action.data };
};
export default clientTransactionReducer;
