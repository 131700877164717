/**
 * Reduer for email templates
 */

import get from 'lodash/get';

import {
  GET_CONFIG_START,
  GET_ACCOUNT_TYPE_DONE,
  GET_APPLICATION_TYPE_DONE,
  GET_DOCUMENT_TYPE_DONE,
  GET_KYC_TYPE_DONE,
  GET_COUNTRIES_DONE,
  GET_CONFIG_DONE
} from '../actions/actionTypes';

const initialState = {
  configLoading: false,
  applicationTypes: [],
  accountTypes: [],
  documentTypes: [],
  leverages: ['1:400'],
  countries: []
};

export const configSelector = (state) => get(state, 'configs', []);
const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONFIG_START:
      return {
        ...state,
        configLoading: true
      };
    case GET_CONFIG_DONE:
      return {
        ...state,
        configLoading: false
      };
    case GET_APPLICATION_TYPE_DONE:
      return {
        ...state,
        configLoading: false,
        applicationTypes: action.payload
      };
    case GET_ACCOUNT_TYPE_DONE:
      return {
        ...state,
        configLoading: false,
        accountTypes: action.payload
      };
    case GET_DOCUMENT_TYPE_DONE:
      return {
        ...state,
        configLoading: false,
        documentTypes: action.payload
      };
    case GET_COUNTRIES_DONE:
      return {
        ...state,
        configLoading: false,
        countries: action.payload
      };
    case GET_KYC_TYPE_DONE:
      return {
        ...state,
        configLoading: false,
        kycRequirements: action.payload
      };
    default:
      return state;
  }
};

export default configReducer;
