const storedUserKey = 'CurrentUser';

export const setUser = (payload) => {
  const json = payload ? JSON.stringify(payload) : null;
  if (json) {
    localStorage.setItem(storedUserKey, json);
  } else {
    localStorage.removeItem(storedUserKey);
  }
};

export const removeUser = (payload) => {
  localStorage.removeItem(storedUserKey);
};

export const isLogin = () => {
  try {
    const storedUser = localStorage.getItem(storedUserKey);
    const data = storedUserKey ? JSON.parse(storedUser) : null;
    return data;
  } catch (error) {
    return error;
  }
};
