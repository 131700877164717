/**
 * Reduer for Whatsapp
 */

import _ from 'lodash';
import {
  FETCH_WA_SESSION_STATUS,
  SEND_MESSAGE,
  UPDATE_QR_CODE_STATUS
} from '../actions/actionTypes';

const initialState = {
  statusData: {},
  isLoading: false,
  sourcesLoading: false,
  qrCode: '',
  sendMsgs: [],
  userDetails: {},
  waInstance: '',
  autheticateStatus: ''
};

const whatsappReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WA_SESSION_STATUS:
      return {
        ...state,
        qrCode:
          state.qrCode === '' ? action.data.statusData.qrCode : state.qrCode,
        userDetails:
          state.userDetails === ''
            ? action.data.userDetails
            : state.userDetails,
        waInstance:
          state.waInstance === ''
            ? action.data.statusData.waInstance
            : state.waInstance,
        ...action.data
      };

    case SEND_MESSAGE:
      // eslint-disable-next-line no-case-declarations
      let stateMsgs = _.cloneDeep(state.sendMsgs);
      stateMsgs.push(action.data.sendMsgs);
      return {
        ...state,
        sendMsgs: stateMsgs
      };
    // case UPDATE_QR_CODE_STATUS:

    //   let statusData = _.cloneDeep(state.statusData);
    //   if (statusData === null || statusData === undefined) statusData = {}
    //   statusData['isAuthenticated'] = true
    //   return {
    //      ...state,
    //      sendMsgs: stateMsgs };
    case UPDATE_QR_CODE_STATUS:
      // eslint-disable-next-line no-case-declarations
      let statusData = _.cloneDeep(state.statusData);
      if (statusData === null || statusData === undefined) statusData = {};
      statusData['isAuthenticated'] = true;
      return {
        ...state,
        statusData: statusData,
        autheticateStatus: action.data.autheticateStatus
      };
    default:
      return state;
  }
};

export default whatsappReducer;
