import React from 'react';

import clsx from 'clsx';

import { connect } from 'react-redux';

// import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import { setSidebarToggleMobile } from '../../store/reducers/ThemeOptions';
// import HeaderMenu from '../HeaderMenu';

import HeaderNotification from '../../layout-components/HeaderNotification';
import HeaderUserbox from '../../layout-components/HeaderUserbox';
import HeaderDots from 'layout-components/HeaderDots';
import { markNotificationsRead } from '../../store/reducers/notificationReducer'

const Header = (props) => {
  const {
    headerShadow,
    headerBgTransparent,
    sidebarToggleMobile,
    setSidebarToggleMobile
  } = props;

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  return (
    <>
      <div
        className={clsx('app-header', {
          'app-header--shadow': headerShadow,
          'app-header--opacity-bg': headerBgTransparent
        })}>
        <div className="app-header--pane">
          <button
            className={clsx(
              'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
              { 'is-active': sidebarToggleMobile }
            )}
            onClick={toggleSidebarMobile}>
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
          <div className="ml-5">
            {/* <HeaderMenu /> */}
          </div>
        </div>
        <div className="app-header--pane">
          <HeaderNotification />
          <HeaderDots 
            userId={props.userId} 
            notifications={props.notification.notifications} 
            markNotificationsRead={props.markNotificationsRead} />

          <HeaderUserbox />
            
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerBgTransparent: state.ThemeOptions.headerBgTransparent,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  notification: state.notification,
  userId: state.dashboardReducer.user._id,
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
  markNotificationsRead: (data) => dispatch(markNotificationsRead(data)),

});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
