/**
 * Reduer for email templates
 */
import get from 'lodash/get';
import {
  FETCH_REQUESTS,
  UPDATE_CHANGE_LEVERAGE_REQUEST,
  RESET_CHANGE_LEVERAGE_REQUEST_REDUCER,
} from '../actions/actionTypes';
import { updateItemInItems } from '../../utils';

const initialState = {
  requests: [],
  isLoading: false,
  page: 1,
  limit: 10
};

export const requestSelector = (state) =>
  get(state, 'changeLeverageRequests', []);
const requestReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REQUESTS:
      return { ...state, ...action.data };
    case UPDATE_CHANGE_LEVERAGE_REQUEST:
      return updatedRequests(state, action);
    case RESET_CHANGE_LEVERAGE_REQUEST_REDUCER:
      return initialState;
    default:
      return state;
  }
};

const updatedRequests = (state, action) => {
  const { request, ...rest } = action.data;
  if (request) {
    const { index, status } = request;
    return {
      ...state,
      requests: updateItemInItems(state.requests, index, { ...request }),
      ...rest
    };
  }
  return { ...state, ...action.data };
};
export default requestReducer;
