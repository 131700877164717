/**
 * Reduer for email templates
 */

import get from 'lodash/get';

import {
  FETCH_CLIENTS,
  FETCH_ALL_CUSTOMERS,
  GET_ACCOUNT_CLIENT,
  GET_CLIENT_LIVE_ACCOUNT,
  ADD_CLIENT,
  CLIENT_ASSIGN_SALES,
  FETCH_SUB_IBS,
  FETCH_SOURCES,
  RESET_PAGE_CLIENTS
} from '../actions/actionTypes';
import { replaceArrayItemsWithAnotherArrayById } from '../../utils';

const initialState = {
  clients: [],
  customers: [],
  isLoading: false,
  count: 0,
  page: 1,
  totalPages: 1,
  accountClient: {},
  liveAccounts: [],
  ibAccounts: [],
  clientAccounts: [],
  subIbs: [],
  subIbsLoading: false,
  sources: [],
  sourcesLoading: false,
};

export const clientsSelector = (state) => get(state, 'clients', []);
const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CLIENT:
    case FETCH_CLIENTS:
    case FETCH_ALL_CUSTOMERS:
    case GET_ACCOUNT_CLIENT:
    case GET_CLIENT_LIVE_ACCOUNT:
      return { ...state, ...action.data };
    case CLIENT_ASSIGN_SALES:
      return assignSalesAgent(state, action);
    case FETCH_SUB_IBS:
      return { ...state, ...action.data };
    case FETCH_SOURCES:
      return { ...state, ...action.data };
    case RESET_PAGE_CLIENTS:
      return { ...initialState };
    default:
      return state;
  }
};

const assignSalesAgent = (state, action) => {
  const { assignClient, ...rest } = action.data;
  if (assignClient) {
    const { customers } = assignClient;
    return {
      ...state,
      ...rest,
      clients: replaceArrayItemsWithAnotherArrayById(state.clients, customers)
    };
  } else {
    return {
      ...state,
      ...rest
    };
  }
};

export default clientReducer;
