/**
 * Reduer for user
 */

import { USER_LOGIN_SUCCESS, GET_ALL_CALENDAR, TWOFA_GENERATE_SUCCESS, TWOFA_VERIFY_SUCCESS, TWOFA_READY_SUCCESS, TWOFA_GENERATE_START, TWOFA_GENERATE_ERROR } from '../actions/actionTypes';

const initialState = {
  isLoggedIn: false,
  email: '',
  phone: '',
  name: '',
  token: '',
  isLoading: false,
  reminders: [],
  qrUri: null,
  showTwoFA: false,
  readyTwoFA: false,
  generateLoading: false
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_SUCCESS:
      console.log('testing', action.currentUser);
      return {
        ...action.currentUser,
        showTwoFA: !action.currentUser.twoFA,
      };
    case TWOFA_GENERATE_START:
      return {
        ...state,
        generateLoading: true
      };
    case TWOFA_GENERATE_SUCCESS:
      return {
        ...state,
        qrUri: action.data && action.data.qrURI,
        generateLoading: false
      };
    case TWOFA_GENERATE_ERROR:
      return {
        ...state,
        generateLoading: false
      };
    case TWOFA_VERIFY_SUCCESS:
      return {
        ...state,
        showTwoFA: false,
        message: 'MFA is successfully enabled for this account'
      };
    case TWOFA_READY_SUCCESS:
      return {
        ...state,
        readyTwoFA: action.payload
      };
    case GET_ALL_CALENDAR:
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};

export default AuthReducer;
