import React, { useState } from 'react';
import { connect } from 'react-redux';

import NotificationsIcon from '@material-ui/icons/Notifications';
import {
  Typography,
  Badge,
  Menu,
  Button,
  List,
  ListItem,
  Tooltip,
  Divider
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

const StyledBadge = withStyles({
  badge: {
    backgroundColor: 'var(--success)',
    color: 'var(--success)',
    boxShadow: '0 0 0 2px #fff',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""'
    }
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0
    }
  }
})(Badge);

const HeaderNotification = (props) => {
  const { myProfile } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="app-header-notification">
        <Button
          variant="text"
          onClick={handleClick}
          className="ml-2 btn-transition-none text-left ml-2 mr-2 p-0 bg-transparent d-flex align-items-center"
          disableRipple>
          {
            <div className="d-block p-0">
              {/* <StyledBadge
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                badgeContent=" "
                classes={{ badge: 'bg-success badge-circle border-0' }}
                variant="dot">
                <NotificationsIcon
                  className="notification-icon"
                  fontSize="inherit"
                  color="inherit"
                />
              </StyledBadge> */}
            </div>
          }
        </Button>
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={Boolean(anchorEl)}
        classes={{ list: 'p-0' }}
        onClose={handleClose}>
        <div className="dropdown-menu-lg overflow-hidden p-0">
          <div className="d-flex px-3 pt-3 align-items-center justify-content-between">
            <Typography className="text-capitalize pl-1 font-weight-bold text-primary">
              <span>Profile Options</span>
            </Typography>
          </div>
          <List
            component="div"
            className="nav-neutral-primary text-left d-flex align-items-center flex-column px-3 pb-3">
            <ListItem button className="d-block text-left">
              My Account
            </ListItem>
            <ListItem button className="d-block text-left">
              Profile settings
            </ListItem>
            <ListItem button className="d-block text-left">
              Active tasks
            </ListItem>
          </List>
          <Divider className="w-100" />
          <List
            component="div"
            className="nav-neutral-primary text-left d-flex align-items-center flex-column px-3 pb-3">
            <ListItem button className="d-block text-left">
              Logout
            </ListItem>
          </List>
        </div>
      </Menu>
    </>
  );
};

const mapStateToProps = (state) => ({
  myProfile: state.dashboardReducer
});

export default connect(mapStateToProps)(HeaderNotification);
