import {
  BEGIN_API_CALL,
  END_API_CALL,
  API_CALL_ERROR
} from '../actions/actionTypes';
import initialState from '../config/initialState';

function actionTypeEndsInSuccess(type) {
  return type.substring(type.length - 8) === '_SUCCESS';
}

export default function apiCallStatusReducer(
  state = initialState.apiCallsInProgress,
  action
) {
  if (action.type === BEGIN_API_CALL) {
    return state <= 0 ? 1 : state + 1;
  } else if (
    action.type === API_CALL_ERROR ||
    action.type === END_API_CALL ||
    actionTypeEndsInSuccess(action.type)
  ) {
    return state <= 0 ? 0 : state - 1;
  }
  return state;
}
