import React from 'react';

import { Badge, Button } from '@material-ui/core';

import avatar2 from '../../assets/images/avatars/avatar2.jpg';
import { NavLink } from 'react-router-dom';

const SidebarUserbox = () => {
  return (
    <>
      <div className="app-sidebar--userbox">
        <div className="avatar-icon-wrapper avatar-icon-md">
          <Badge
            variant="dot"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            badgeContent=" "
            overlap="circle"
            classes={{ badge: 'bg-success badge-circle' }}>
            <div className="avatar-icon rounded-circle">
              <img alt="..." src={avatar2} />
            </div>
          </Badge>
        </div>
        <div className="my-3 userbox-details">
          <span>Emma Taylor</span>
          <small className="d-block text-white-50">
            (emma.taylor@uifort.com)
          </small>
        </div>
        <Button
          component={NavLink}
          to="/PageProfile"
          size="small"
          className="btn-userbox">
          View profile
        </Button>
      </div>
    </>
  );
};

export default SidebarUserbox;
