import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Box,
  Typography,
  Popover,
  Badge,
  Button,
  Divider
} from '@material-ui/core';

import NotificationsActiveTwoToneIcon from '@material-ui/icons/NotificationsActiveTwoTone';

import PerfectScrollbar from 'react-perfect-scrollbar';
// import { readNotifications } from '../../websockets';
// import { markNotificationsRead } from '../../reducers/Notification'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const HeaderDots = (props) => {
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);

  const handleClick1 = (event, notifications) => {
    setAnchorEl1(event.currentTarget);
    // readNotifications(props.customerId, notifications)
    props.markNotificationsRead(notifications);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const open1 = Boolean(anchorEl1);

  const [value, setValue] = useState(0);

  const getbellColor = (notifications = []) => {
    let classes =
      'font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-44 rounded position-relative';
    if (notifications.filter((obj) => obj.isRead === false).length === 0) {
      return classes + ' btn-transition-none  text-primary  ';
    }
    if (
      notifications.filter(
        (obj) =>
          obj.isRead === false && obj.data && obj.data.status === 'REJECTED'
      ).length > 0
    ) {
      return classes + '  btn-transition-none bg-neutral-danger text-danger ';
    }
    return classes + '  btn-transition-none bg-neutral-success text-success ';
  };

  return (
    <>
      <div className="d-flex align-items-center popover-header-wrapper">
        <span className="pr-2">
          {/* <Badge
            // variant="dot"
            // anchorOrigin={{
            //   vertical: 'top',
            //   horizontal: 'left'
            // }}
            // badgeContent=" "
            // classes={{ badge: getbellColor(props.notifications) }}
            >
            <Button
              onClick={(e)=>{
                handleClick1(e, props.notifications)
              }}
              className={getbellColor(props.notifications)}>
              <span>
                <NotificationsActiveTwoToneIcon />
              </span>
            </Button>
          </Badge> */}

          <Popover
            open={open1}
            anchorEl={anchorEl1}
            onClose={handleClose1}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            classes={{
              paper: 'app-header-dots'
            }}>
            <div className="popover-custom-lg overflow-hidden">
              <div className="bg-composed-wrapper bg-midnight-bloom border-0 text-center rounded-sm m-2">
                <div className="bg-composed-img-3 bg-composed-wrapper--image" />
                <div className="bg-composed-wrapper--content text-white px-2 py-4">
                  <h4 className="font-size-xl font-weight-bold mb-2">
                    Notifications
                  </h4>
                  <p className="opacity-8 mb-0">
                    You have <b className="text-success">{props.length}</b>{' '}
                    notificatoins
                  </p>
                </div>
              </div>
              {/* <div className="mx-2">
                <Tabs
                  className="nav-tabs-primary"
                  value={value}
                  variant="fullWidth"
                  onChange={handleChange}>
                  <Tab label="Timeline" />
                </Tabs>
              </div> */}
              <div className="height-280">
                <PerfectScrollbar>
                  <TabPanel value={value} index={0}>
                    {props.notifications.map((obj, index) => {
                      return (
                        <div
                          key={index}
                          className="timeline-list  timeline-list-offset-dot">
                          <div
                            className="timeline-item"
                            style={{ margin: '0px' }}>
                            {/* <div className="timeline-item-offset">9:25</div> */}
                            <div className="timeline-item--content">
                              <div className="timeline-item--icon timeline-item--icon-reject" />
                              {obj.data && (
                                <>
                                  {obj.data.status === 'APPROVED' && (
                                    <div className="timeline-item--icon timeline-item--icon-approve" />
                                  )}

                                  {obj.data.status === 'REJECTED' && (
                                    <div className="timeline-item--icon timeline-item--icon-reject" />
                                  )}
                                </>
                              )}
                              <h4 className="timeline-item--label mb-2 font-weight-bold">
                                {obj.data &&
                                  (obj.data.transactionId || obj.data.recordId)}
                              </h4>
                              <p>{obj.message}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </TabPanel>
                </PerfectScrollbar>
              </div>
              <Divider />
              <div className="text-center py-3">
                <Badge
                  color="error"
                  // variant="dot"
                  // anchorOrigin={{
                  //   vertical: 'top',
                  //   horizontal: 'right'
                  // }}
                >
                  <Link to={'/Activities'}>
                    <Button
                      className="btn-gradient bg-midnight-bloom px-4"
                      variant="contained">
                      <span className="btn-wrapper--label">
                        See All Activities
                      </span>
                      <span className="btn-wrapper--icon">
                        {/* <FontAwesomeIcon icon={['fas', 'arrow-right']} /> */}
                      </span>
                    </Button>
                  </Link>
                </Badge>
              </div>
            </div>
          </Popover>
        </span>
      </div>
    </>
  );
};

export default HeaderDots;
