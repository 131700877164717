/**
 * Reduer for user
 */

import get from 'lodash/get';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

import {
  LOAD_ROLES_SUCCESS,
  LOAD_ROLES_ERROR,
  DELETE_ROLE_OPTIMISTIC,
  DELETE_ROLE_ERROR,
  CREATE_ROLE_ERROR,
  CREATE_ROLE_SUCCESS,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_ERROR,
  LOAD_ROLE_SUCCESS,
  LOAD_ROLE_ERROR,
  LOAD_PERMISSIONS_SUCCESS,
  LOAD_PERMISSIONS_ERROR,
  RESET_ROLE_REDUCER,
} from '../actions/actionTypes';

const initialState = {
  data: [],
  dataLoading: true,
  errorMessage: '',
  permissions:[]
};

export const rolesSelector = (state) => get(state, 'roles', []);

const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ROLES_SUCCESS:
      return {
        ...state,
        ...action.roles,
        dataLoading:false
      };
    case DELETE_ROLE_OPTIMISTIC:
      return {...state,
        data: state.data.filter((role) => role._id !== action.role._id)
      };
    case LOAD_ROLE_SUCCESS:
      return loadRoleSuccessHandler(state, action);
    case CREATE_ROLE_SUCCESS:
      return { ...state, data: [...state.data, action.role] };
    case LOAD_PERMISSIONS_SUCCESS:
      return { ...state, permissions: action.permissions };
    case UPDATE_ROLE_SUCCESS:
      return state;

    case UPDATE_ROLE_ERROR:
    case LOAD_ROLES_ERROR:
    case CREATE_ROLE_ERROR:    
    case LOAD_ROLE_ERROR:
    case DELETE_ROLE_ERROR:
    case LOAD_PERMISSIONS_ERROR:
      return {
        ...state,
        errorMessage: action.errorMessage,
        isError: action.isError
      };
    case RESET_ROLE_REDUCER: 
      return initialState;
    default:
      return state;
  } 
};

const loadRoleSuccessHandler = (state, action) => {
  const { data } = state;
  if (isEmpty(data)) {
    return { ...state,data: [...data, action.role] };
  } else {
    const isRoleExit = find(data, { _id: action.role && action.role._id });
    if (!isRoleExit) {
      return { ...state,data: [ ...action.role] };
    }
    return state; //data.filter((user) => user._id !== action.user._id)
  }
};

export default roleReducer;
