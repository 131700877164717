/**
 * Reduer for email templates
 */
import get from 'lodash/get';
import {
  FETCH_CLIENT_ACTIVITIES,
  DELETE_CLIENT_ACTIVITIE,
  FETCH_CLIENT_LOGS,
  RESET_PAGE_CLIENT_ACTIVITIES
} from '../actions/actionTypes';

const initialState = {
  activities: {
    data: [],
    isLoading: false,
    page: 1,
    totalPages: 1,
    limit: 5
  },
  logs: {
    data: [],
    isLoading: false,
    page: 1,
    totalPages: 1,
    limit: 5
  }
};

export const clientActivitiesSelector = (state) =>
  get(state, 'clinetActivities', []);

const clientActivityReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CLIENT_ACTIVITIES:
      return { ...state, ...action.data };
    case FETCH_CLIENT_LOGS:
      return { ...state, ...action.data };
    case DELETE_CLIENT_ACTIVITIE:
      return deleteActivity(state, action);
    case RESET_PAGE_CLIENT_ACTIVITIES:
      return { ...initialState };
    default:
      return state;
  }
};

const deleteActivity = (state, action) => {
  const { activity } = action.data;
  if (activity) {
    return {
      ...state,
      activities: state.activities.filter((ele) => ele._id !== activity._id)
    };
  }
  return { ...state, ...action.data };
};

export default clientActivityReducer;
