
// import get from 'lodash/get';
import {
  FETCH_CLIENT_LIST_START,
  FETCH_CLIENT_LIST_END,

  FETCH_DEALS_START,
  FETCH_DEALS_END,
  SET_COMMISION,
  SET_REBATE,
  RESET_PAGE_CLIENT_STATEMENT
} from '../actions/actionTypes';

const initialState = {
  clientList: [],
  loadingClientList: false,

  loadingDealsList: false,
  commissionList: [],
  rebateList: [],

};

const clientActivityReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CLIENT_LIST_START:
      return { ...state, loadingClientList: true };
    case FETCH_CLIENT_LIST_END:
      return { ...state, loadingClientList: false, ...action.data };

    case FETCH_DEALS_START:
      return { ...state, loadingDealsList: true };
    case FETCH_DEALS_END:
      return { ...state, loadingDealsList: false, ...action.data };
    case SET_COMMISION:
      return { ...state, commission: action.data };
    case SET_REBATE:
      return { ...state, rebate: action.data };
    case RESET_PAGE_CLIENT_STATEMENT:
      return { ...initialState };
    default:
      return state;
  }
};

export default clientActivityReducer;
