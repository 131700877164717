/**
 * Reduer for Whatsapp
 */

import _ from 'lodash'
import {
  GET_CAMPAIGN_LIST,
  SET_CAMPAIGN_OBJ,
  RESET_CAMPAIGN_OBJ,
  GET_CAMPAIGN_UNSUBSCRIBERS_LIST
} from '../actions/actionTypes';


const initialState = {
  page: 1,
  totalPages: 1,
  limit: 10,
  campaigns:[],
  campaignsObj:{
    selectedTmplLang: { name: "English", key: "en-gb" }
  },
  isLoading: false,
  isSuccess: false,
  unsubscribers:[]
};

const multiCampaignsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CAMPAIGN_LIST:
      return {
        ...state,
        campaigns: action.data.campaigns,
        ...action.data
      };
    case SET_CAMPAIGN_OBJ:
      let actionObj = _.cloneDeep(action.data)
      return {
        ...state,
        campaignsObj: {
          ...state.campaignsObj,
          ...actionObj
        } 
      }  
      case RESET_CAMPAIGN_OBJ:
        return {
          ...state,
          campaignsObj: { } 
        } 
      case GET_CAMPAIGN_UNSUBSCRIBERS_LIST:
        return {
          ...state,
          unsubscribers: action.data.unsubscribers, 
          ...action.data
        } 
     default:
      return state;
  }
};


export default multiCampaignsReducer;
