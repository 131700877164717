import { createSlice } from '@reduxjs/toolkit';


const slice = createSlice({
  name: 'clientDocuments',
  initialState: {
    data: [],
    isLoading: false,
    isUpdateLoading: false,
    isUploadLoading: false,
    isSuccess: false,
    isError: false
  },
  reducers: {
    loadDocuments: (state, action) => {
      state.data = action.payload;
    },
    addDocument: (state, action) => {
      
      if(action.payload.documentDetails && action.payload.documentDetails.isCorporate){
        const index = state.data.findIndex(document => document.title === action.payload.title && action.payload.documentDetails.corpId ===  document.documentDetails.corpId);
        if (index === -1)
          state.data.push(action.payload);
        else {
          state.data.splice(index, 1);
          state.data.push(action.payload);
        }

      }else{
        const index = state.data.findIndex(document => document.title === action.payload.title);
        if (index === -1)
          state.data.push(action.payload);
        else {
          state.data.splice(index, 1);
          state.data.push(action.payload);
        }
      }
    
    },
    updateDocument: (state, action) => {
      const newData = state.data.map(document => document._id === action.payload.documentId ? action.payload.newDocument : document);
      state.data = newData;
    },
    deleteDocument: (state, action) => {
      const newData = state.data.filter(document => document._id !== action.payload);
      state.data = newData;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setUpdateLoading: (state, action) => {
      state.isUpdateLoading = action.payload;
    },
    setUploadLoading: (state, action) => {
      state.isUploadLoading = action.payload;
    }
  }
});

export const { addDocument, deleteDocument, loadDocuments, setLoading, updateDocument, setUpdateLoading, setUploadLoading } = slice.actions;

export default slice.reducer;