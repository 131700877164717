/**
 * Reduer for email templates
 */

import get from 'lodash/get';

import {
  FETCH_INTERNAL_TRANSFER_TRANSACTIONS,
  UPDATE_INTERNAL_TRANSFER_TRANSACTION,
  ADD_INTERNAL_TRANSFER_TRANSACTION,
  RESET_INTERNAL_TRANSFER_REDUCER
} from '../actions/actionTypes';
import { updateItemInItems } from '../../utils';

const initialState = {
  transactions: [],
  isLoading: false,
  hasUpdate: false,
  page: 1,
  limit: 10
};

export const transactionsSelector = (state) =>
  get(state, 'internalTransferTransactions', []);
const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INTERNAL_TRANSFER_TRANSACTIONS:
      return { ...state, ...action.data };
    case UPDATE_INTERNAL_TRANSFER_TRANSACTION:
      return updatedTransactions(state, action);
    case ADD_INTERNAL_TRANSFER_TRANSACTION:
      return addTransaction(state, action);
    case RESET_INTERNAL_TRANSFER_REDUCER:
      return initialState;
    default:
      return state;
  }
};

const addTransaction = (state, action) => {
  const { transaction, hasUpdate, isLoading } = action.data;
  if (transaction) {
    return {
      ...state,
      isLoading,
      hasUpdate,
      transactions: [transaction, ...state.transactions]
    };
  }
  return { ...state, ...action.data };
};
const updatedTransactions = (state, action) => {
  const { transaction, ...rest } = action.data;
  if (transaction) {
    const { index, status } = transaction;
    return {
      ...state,
      transactions: updateItemInItems(state.transactions, index, { status }),
      ...rest
    };
  }
  return { ...state, ...action.data };
};

export default transactionReducer;
