import socketIOClient from 'socket.io-client';

const SOCKETS_URL = 'http://localhost:3002';

let socket;

export const initSocket = () => {
  socket = socketIOClient(SOCKETS_URL, { transports: ['websocket'] });
  console.log('connecting socket', socket);
  // start();
  return socket;
};

export const readNotifications = (customerId) => {
  socket.emit('readNotifications', { customerId });
};

export const startFunctions = (gotNewNotification, addAll) => {
  
  socket.on('message', (message, callback) => {
    
    gotNewNotification(message);
  });
  socket.on('notification', (message, callback) => {
    
    console.log('got notification ', message);
    gotNewNotification(message);
  });
  socket.on('allNotifications', (message, callback) => {
    
    console.log('got allNotifications ', message);
    addAll(message.notifications);
  });
};
