/**
 * Reduer for email templates
 */
import get from 'lodash/get';
import {
  FETCH_REQUESTS,
  UPDATE_REQUEST,
  GET_CUSTOMER_STRUCTURE_SUCCESS,
  GET_CUSTOMER_STRUCTURE_FAILURE,
  GET_CUSTOMER_STRUCTURE_LOADING,
  RESET_IB_REQUEST_REDUCER,
  RESET_PAGE_CLIENT_PARTNERSHIP
} from '../actions/actionTypes';
import { updateItemInItems } from '../../utils';

const initialState = {
  requests: [],
  isLoading: false,
  page: 1,
  limit: 10,
  customerStrcture: [],
  customerStrctureLoading: false
};

export const requestSelector = (state) => get(state, 'ibRequests', []);
const requestReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMER_STRUCTURE_LOADING:
      return { ...state, customerStrctureLoading: true };
    case FETCH_REQUESTS:
      return { ...state, ...action.data };
    case UPDATE_REQUEST:
      return updatedRequests(state, action);
    case GET_CUSTOMER_STRUCTURE_SUCCESS:
      return {
        ...state,
        customerStrcture: action.data.result,
        customerStrctureLoading: false
      };
    case GET_CUSTOMER_STRUCTURE_FAILURE:
      return {
        ...state,
        customerStrcture: [],
        customerStrctureLoading: false
      };
    case RESET_PAGE_CLIENT_PARTNERSHIP:
      return { ...initialState };
    case RESET_IB_REQUEST_REDUCER:
      return initialState;
    default:
      return state;
  }
};

const updatedRequests = (state, action) => {
  const { request, ...rest } = action.data;
  if (request) {
    const { index, status } = request;
    return {
      ...state,
      requests: updateItemInItems(state.requests, index, { status }),
      ...rest
    };
  }
  return { ...state, ...action.data };
};
export default requestReducer;
