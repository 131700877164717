/**
 * Reduer for email templates
 */
import get from 'lodash/get';
import {
  FETCH_DOCUMENT,
  UPLOAD_DOCUMENT,
  FETCH_DOCUMENT_TYPES,
  UPDATE_CUSTOMER_DOCUMENT,
  DELETE_CUSTOMER_DOCUMENT,
  SAVE_CUSTOMER_DOCUMENT,
  RESET_PAGE_CLIENT_DOCUMENTS,
  PATCH_DOCUMENT_TYPES
} from '../actions/actionTypes';
import { updateItemInItems } from '../../utils';

const initialState = {
  documents: [],
  documentTitles: [],
  documentTitlesOg: [],
  isLoading: false
};

const resetState = {
  documents: [],
  isLoading: false
};

export const documentsSelector = (state) => get(state, 'documents', []);
const documentReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DOCUMENT:
      
      return { ...state, ...action.data };
    case UPLOAD_DOCUMENT:
      
      return { ...state, ...action.data };
    case SAVE_CUSTOMER_DOCUMENT:
      
      return addDocumet(state, action);
    case UPDATE_CUSTOMER_DOCUMENT:
      
      return updatedDocumnets(state, action);
    case DELETE_CUSTOMER_DOCUMENT:
      
      return deleteDocumet(state, action);
    case FETCH_DOCUMENT_TYPES:
      
      return { ...state, ...action.data };
    case RESET_PAGE_CLIENT_DOCUMENTS:
      
      return { ...initialState, ...resetState, documentTitles: state.documentTitles, documentTitlesOg: state.documentTitlesOg };
    case PATCH_DOCUMENT_TYPES:
      return { ...state, 
        documentTitles: action.data.documentTitles,
        documentTitlesOg: action.data.documentTitlesOg,
       };
      
    default:
      return state;
  }
};

const addDocumet = (state, action) => {
  const { document, ...rest } = action.data;
  if (document) {
    return { ...state, documents: [...state.documents, document], ...rest };
  }
  return { ...state, ...action.data };
};

const deleteDocumet = (state, action) => {
  const { document } = action.data;
  if (document) {
    return {
      ...state,
      documents: state.documents.filter((doc) => doc._id !== document._id)
    };
  }
  return { ...state, ...action.data };
};

// const updatedDocumnets = (state, action) => {
//   const { document } = action;
//   if (document) {
//     const filteredData = state.documents.filter(
//       (document) => document._id !== action.document._id
//     );
//     return { ...state, documents: [...filteredData, document] };
//   }
//   return { ...state, ...action.data };
// };

const updatedDocumnets = (state, action) => {
  const { document, ...rest } = action.data;
  if (document) {
    const { index, status } = document;
    return {
      ...state,
      documents: updateItemInItems(state.documents, index, { status }),
      ...rest
    };
  }
  return { ...state, ...action.data };
};
export default documentReducer;
