import {
  GET_CLIENT_CALENDAR,
  GET_CLIENT_NOTES,
  ADD_CLIENT_NOTE,
  CHANGE_CLIENT_NOTE,
  RESET_PAGE_CLIENT_LOGS
} from '../actions/actionTypes';

const initialState = {
  isLoading: false,
  hasUpdate: false,
  page: 1,
  totalPages: 1,
  notes: [],
  calendar: []
};

const clientNotesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLIENT_CALENDAR:
    case GET_CLIENT_NOTES:
    case ADD_CLIENT_NOTE:
    case CHANGE_CLIENT_NOTE:
      return { ...state, ...action.data };
    case RESET_PAGE_CLIENT_LOGS:
      return { ...initialState };
    default:
      return state;
  }
};

export default clientNotesReducer;
