/**
 * Reduer for email templates
 */

import {
  GET_EMAIL_TEMPLATES,
  FETCH_EMAIL_TEMPLATES,
  FETCH_CUSTOM_EMAIL_TEMPLATES,
  FETCH_EMAIL_TEMPLATE_DETAIL,
  UPDATE_EMAIL_TEMPLATE_DETAIL,
  RESET_EMAIL_TEMPLATE_REDUCER
} from '../actions/actionTypes';

const initialState = {
  emailTemplates: [],
  customTemplates: [],
};
const emailTemplateReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EMAIL_TEMPLATES:
      return state;
    case FETCH_EMAIL_TEMPLATES:
      return { ...state, ...action.data };
    case FETCH_CUSTOM_EMAIL_TEMPLATES:
      return { ...state, ...action.data };
    case FETCH_EMAIL_TEMPLATE_DETAIL:
      return { ...state, ...action.data };
    case UPDATE_EMAIL_TEMPLATE_DETAIL:
      return { ...state, ...action.data };
      case RESET_EMAIL_TEMPLATE_REDUCER:
        return initialState;
    default:
      return state;
  }
};

export default emailTemplateReducer;
