/**
 * Reduer for bank details
 */

import {
  GET_BANK_ACCOUNT,
  FETCH_BANK_ACCOUNTS,
  ADD_BANK_ACCOUNT,
  UPDATE_BANK_ACCOUNT,
  REMOVE_BANK_ACCOUNT,
  RESET_PAGE_CLIENT_BANK_DETAILS,
  RESET_BANK_ACCOUNT_REDUCER,
} from '../actions/actionTypes';

const initialState = {
  bankAccounts: [],
  isLoading: false,
  hasUpdate: false,
  limit: 5,
  page: 1,
  totalPages: 1
};
const accountDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BANK_ACCOUNT:
      return { ...state, ...action.data };
    case FETCH_BANK_ACCOUNTS:
      return { ...state, ...action.data };
    case ADD_BANK_ACCOUNT:
      return { ...state, ...action.data };
    case UPDATE_BANK_ACCOUNT:
      return { ...state, ...action.data };
    case REMOVE_BANK_ACCOUNT:
      return { ...state, ...action.data };
    case RESET_PAGE_CLIENT_BANK_DETAILS:
      return { ...initialState };
    case RESET_BANK_ACCOUNT_REDUCER:
      return initialState;
    default:
      return state;
  }
};

export default accountDetailsReducer;
