import React from 'react';

import PageError4031 from '../../components/PageError403';
export default function PageError403() {
  return (
    <>
      <PageError4031 />
    </>
  );
}
