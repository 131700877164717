/**
 * Reduer for Whatsapp
 */

 import _ from 'lodash'
 import {
  SEND_TEXT_MESSAGE
 } from '../actions/actionTypes';
 import { updateItemInItems } from '../../utils';
 
 const initialState = {
  smsDetails:[]
 };
 
 const textSmsReducer = (state = initialState, action) => {
   switch (action.type) {
    
     case SEND_TEXT_MESSAGE:
 
       let stateMsgs =  _.cloneDeep(state.sendMsgs) || []
        stateMsgs.push(action.data.sendMsgs)
       return {
          ...state, 
          smsDetails: stateMsgs };
     default:
       return state;
   }
 };
 
 
 export default textSmsReducer;