/**
 * Reduer for user
 */

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import {
  LOAD_USERS_SUCCESS,
  LOAD_USERS_LOADING,
  LOAD_USERS_ERROR,
  DELETE_USER_OPTIMISTIC,
  DELETE_USER_ERROR,
  USER_STATUS_UPDATE_OPTIMISTIC,
  USER_STATUS_UPDATE_ERROR,
  CREATE_USER_ERROR,
  CREATE_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  LOAD_USER_SUCCESS,
  LOAD_USER_ERROR,
  CHANGE_USER_PASSWORD,
  CHANGE_HAS_UPDATE,
  RESET_USER_PASSWORD,
  USER_LOG_ACCESS,
  GET_ALL_USERS
} from '../actions/actionTypes';

const initialState = {
  page: 1,
  totalPages: 1,
  limit: 10,
  data: [],
  isError: false,
  isSuccess: false,
  errorMessage: '',
  isLoading: false,
  hasUpdate: false,
  userLogs: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    docs: []
  },
  userList: {
    isLoading: false,
    users: []
  }
};

export const userSelector = (state) => get(state, 'users', []);

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USERS_SUCCESS:
      return { ...state, ...action.users };
    case LOAD_USERS_LOADING:
      return {
        ...state,
        isSuccess: false,
        totalPages: 1
      };
    case LOAD_USER_SUCCESS:
      return loadUserSuccessHandler(state, action);
    case DELETE_USER_OPTIMISTIC:
      return {
        ...state,
        data: state.data.filter((user) => user._id !== action.user._id)
      };
    case USER_STATUS_UPDATE_OPTIMISTIC:
      return {
        ...state,
        data: state.data.map((user) =>
          user._id === action.user._id ? action.user : user
        )
      };
    case CREATE_USER_SUCCESS:
      return { ...state, data: [...state.data, action.user], errorMessage: '', isError: false };
    case UPDATE_USER_SUCCESS:
      return { ...state, ...action.data };
    case CHANGE_USER_PASSWORD:
    case RESET_USER_PASSWORD:
    case CHANGE_HAS_UPDATE:
      return { ...state, ...action.data };
    case LOAD_USER_ERROR:
    case LOAD_USERS_ERROR:
    case UPDATE_USER_ERROR:
    case DELETE_USER_ERROR:
    case USER_STATUS_UPDATE_ERROR:
    case CREATE_USER_ERROR:
      return { ...state, errorMessage: action.errorMessage, isError: action.isError };
    case USER_LOG_ACCESS:
      return { ...state, userLogs: action.data };
    case GET_ALL_USERS:
      return { ...state, userList: action.data };
    default:
      return state;
  }
};

const loadUserSuccessHandler = (state, action) => {
  const { data } = state;
  if (isEmpty(data)) {
    return { ...state, data: [...data, action.user] };
  } else {
    const isUserExit = find(data, { _id: action.user && action.user._id });
    if (!isUserExit) {
      return { ...state, data: [...data, action.user] };
    }
    return state;
  }
};

export default userReducer;
