/**
 * Reduer for promotion
 */

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import {
  LOAD_PROMOTIONS_SUCCESS,
  LOAD_PROMOTION_SUCCESS,
  DELETE_PROMOTION_SUCCESS,
  CREATE_PROMOTION_SUCCESS,
  UPDATE_PROMOTION_SUCCESS,
  LOAD_PROMOTION_ERROR,
  LOAD_PROMOTIONS_ERROR,
  UPDATE_PROMOTION_ERROR, DELETE_PROMOTION_ERROR, CREATE_PROMOTION_ERROR, LOAD_ACCOUNTPROMOS_ERROR, LOAD_ACCOUNTPROMOS_SUCCESS
} from '../actions/actionTypes';

const initialState = {
  page: 1,
  totalPages: 1,
  limit: 10,
  data: [],
  isError: false,
  isSuccess: false,
  errorMessage: '',
  isLoading: false,
  hasUpdate: false,
  accountCampaigns: []
};

export const utmPromotionSelector = (state) => get(state, 'utmPromotion', []);

const utmPromotionReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PROMOTIONS_SUCCESS:
      return { ...state, ...action.promotions };
    case LOAD_ACCOUNTPROMOS_SUCCESS:
      return { ...state, accountCampaigns: action.promotions };
    case LOAD_PROMOTION_SUCCESS:
      return loadPromotionSuccessHandler(state, action);
    case DELETE_PROMOTION_SUCCESS:
      return {
        ...state,
        data: state.data.filter((promotion) => promotion._id !== action.promotion._id)
      };
    case CREATE_PROMOTION_SUCCESS:
      return { ...state, data: [...state.data, action.promotion],errorMessage: '', isError: false  };
    case UPDATE_PROMOTION_SUCCESS:
      return { ...state, ...action.data };
    case LOAD_PROMOTION_ERROR:
    case LOAD_PROMOTIONS_ERROR:
    case LOAD_ACCOUNTPROMOS_ERROR:
    case UPDATE_PROMOTION_ERROR:
    case DELETE_PROMOTION_ERROR:
    case CREATE_PROMOTION_ERROR:
      return { ...state, errorMessage: action.errorMessage, isError: action.isError };
    
    default:
      return state;
  }
};

const loadPromotionSuccessHandler = (state, action) => {
  const { data } = state;
  if (isEmpty(data)) {
    return { ...state, data: [...data, action.promotion] };
  } else {
    const isPromotionExit = find(data, { _id: action.promotion && action.promotion._id });
    if (!isPromotionExit) {
      return { ...state, data: [...data, action.promotion] };
    }
    return state;
  }
};

export default utmPromotionReducer;
