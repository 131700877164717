import {
  LOAD_TARGET_SUCCESS,
  CREATE_TARGET_SUCCESS,
  SALE_TARGET_SUCCESS,
  SALE_TARGET_ERROR,
  LOAD_TARGET_ERROR,
  CREATE_TARGET_ERROR,
  GET_ALL_USER,
  GET_ALL_USER_LOADING,
  GET_ALL_USER_ERROR,
  RESET_TARGET_REDUCER,
  LOADING_TARGET,
  SALE_TARGET_LOADING,
} from '../actions/actionTypes';

const initialState = {
  page: 1,
  totalPages: 1,
  limit: 10,
  targets: [],
  targetsLoading: true,
  allUsers: [],
  allUsersLoading: true,
  isError: false,
  isSuccess: false,
  errorMessage: '',
  isLoading: false,
  hasUpdate: false,
  saleTargetD: [],
  saleTargetDLoading: true,
};


const targetReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_TARGET:
      return {
        ...state,
        targetsLoading: state.targetsLoading
      };
    case LOAD_TARGET_SUCCESS:
      return {
        ...state,
        targets: action.payload.result,

        targetsLoading: false,
        allUsers: action.payload.allusers,
        allUsersLoading: false
      };
    case CREATE_TARGET_SUCCESS:
    // var newArry = []
    // state.targets.map(v => {
    //   if (action.payload._id == v._id) {
    //     newArry.push(action.payload)
    //   } else {
    //     newArry.push(v)
    //   }
    // })
    // return {
    //   ...state,
    //   targets: newArry,
    // };
    case GET_ALL_USER:
      return {
        ...state,
        allUsers: action.data.data,
        allUsersLoading: false
      };
    case GET_ALL_USER_LOADING:
      return {
        ...state,
        allUsersLoading: true
      };
    case GET_ALL_USER_ERROR:
      return {
        ...state,
        allUsersLoading: false
      };
    case SALE_TARGET_LOADING:
      return {
        ...state,
        saleTargetDLoading: true,
      };
    case SALE_TARGET_SUCCESS:
      return {
        ...state,
        saleTargetD: action.payload,
        saleTargetDLoading: false,
      };
    case SALE_TARGET_ERROR:
      return { errorMessage: action.errorMessage, isError: action.isError };
    case RESET_TARGET_REDUCER:
      return initialState;
    case LOAD_TARGET_ERROR:
    case CREATE_TARGET_ERROR:
    default:
      return state;
  }
};

export default targetReducer;
