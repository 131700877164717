/**
 * Reducer for ib
 */

import get from 'lodash/get';
import {
  CHANGE_HAS_UPDATE,
  LOAD_STRUCTURES_SUCCESS,
  LOAD_STRUCTURES_LOADING,
  CREATE_STRUCTURE_SUCCESS,
  UPDATE_STRUCTURE_SUCCESS,
  LOAD_STRUCTURES_ERROR,
  CREATE_STRUCTURE_ERROR,
  DELETE_STRUCTURE_SUCCESS,
  UPDATE_STRUCTURE_ERROR,
  LOAD_IB_PREFILL_ERROR,
  LOAD_IB_PREFILL_SUCCESS,
  RESET_PAGE_CLIENT_PARTNERSHIP
} from '../actions/actionTypes';
import { CP_REGISTER_DEMO, CP_REGISTER_LIVE } from '../../settings';

const initialState = {
  page: 1,
  totalPages: 1,
  limit: 10,
  structures: [],
  structuresLoading: true,
  links: {},
  prefill: {},
  isError: false,
  isSuccess: false,
  errorMessage: '',
  isLoading: false,
  hasUpdate: false
};

export const ibSelector = (state) => get(state, 'ib', []);

const ibReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_STRUCTURES_LOADING:
      return {
        ...state,
        structuresLoading: true
      };
    case LOAD_STRUCTURES_SUCCESS:
      return {
        ...state,
        structures: action.payload,
        structuresLoading: false
      };
    case LOAD_IB_PREFILL_SUCCESS:
      return {
        ...state,
        prefill: action.payload
      };
    case CREATE_STRUCTURE_SUCCESS:
      return { ...state, structures: [...state.structures, action.structure] };
    case DELETE_STRUCTURE_SUCCESS:
      const fil = state.structures.filter(item => item._id != action.structure);
      return { ...state, structures: fil };
    case UPDATE_STRUCTURE_SUCCESS:
      var temp = []
      state.structures.forEach(item => {
        if (item._id === action.data._id) {
          temp.push(action.data)
        } else {
          temp.push(item)
        }
      })
      return { ...state, structures: temp };
    case CHANGE_HAS_UPDATE:
      return { ...state, ...action.data };
    case LOAD_STRUCTURES_ERROR:
    case CREATE_STRUCTURE_ERROR:
    case UPDATE_STRUCTURE_ERROR:
    case LOAD_IB_PREFILL_ERROR:
      return { errorMessage: action.errorMessage, isError: action.isError };
    case RESET_PAGE_CLIENT_PARTNERSHIP:
      return { ...initialState };
    default:
      return state;
  }
};

export default ibReducer;
