export const GOT_NEW_NOTIFICATION = 'GOT_NEW_NOTIFICATION';
export const GOT_NOTIFICATION_ARRAY = 'GOT_NOTIFICATION_ARRAY';
export const REPLACE_NOTIFICATIONS = 'REPLACE_NOTIFICATIONS';

export const gotNewNotification = (notification) => async (dispatch) => {
  dispatch({ type: GOT_NEW_NOTIFICATION, payload: notification });
};

export const addNotifications = (notifications) => async (dispatch) => {
  dispatch({ type: GOT_NOTIFICATION_ARRAY, payload: notifications });
};

export const markNotificationsRead = (notifications) => async (dispatch) => {
  let notifs = notifications.map((obj) => {
    return {
      ...obj,
      isRead: true
    };
  });
  dispatch({ type: REPLACE_NOTIFICATIONS, payload: notifs });
};

export default function reducer(
  state = {
    haveNewNotification: false,
    notifications: []
  },
  action
) {
  switch (action.type) {
    case GOT_NEW_NOTIFICATION:
      let newNotif = [action.payload];
      if (
        state.notifications.filter(
          (obj) => obj.data.recordId === action.payload.data.recordId
        ).length === 0
      ) {
        newNotif = newNotif.concat(state.notifications);
      } else {
        newNotif = state.notifications;
      }
      return {
        ...state,
        notifications: newNotif,
        haveNewNotification: true
      };
    case GOT_NOTIFICATION_ARRAY:
      let tmp = state.notifications;
      tmp = tmp.concat(action.payload);
      return {
        ...state,
        notifications: tmp,
        haveNewNotification: true
      };
    case REPLACE_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload
        // haveNewNotification: true,
      };
    default:
      break;
  }
  return state;
}
