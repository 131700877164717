/**
 * Reduer for email templates
 */

import get from 'lodash/get';

import { FETCH_WIRE_TRANSFER_TRANSACTIONS, UPDATE_WIRE_TRANSFER_TRANSACTION, ADD_WIRE_TRANSFER_TRANSACTION } from '../actions/actionTypes';
import {updateItemInItems} from "../../utils";

const initialState = {
  transactions: [],
  isLoading: false,
  page: 1,
  limit: 10
};

export const transactionsSelector = (state) => get(state, 'wireTransferTransactions', []);
const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WIRE_TRANSFER_TRANSACTIONS:
      return { ...state, ...action.data };
    case UPDATE_WIRE_TRANSFER_TRANSACTION:
      return updatedTransactions(state, action);
    case ADD_WIRE_TRANSFER_TRANSACTION:
      return addTransaction(state, action);
    default:
      return state;
  }
};

const addTransaction = (state, action) => {
  const { transaction } = action.data;
  if (transaction) {
    return { ...state, transactions: [...state.transactions, transaction] };
  }
  return { ...state, ...action.data };
};
const updatedTransactions = (state, action) => {
  const { transaction,...rest } = action.data;
  if (transaction) {
   const {index,status} = transaction;
    return { ...state, transactions: updateItemInItems(state.transactions, index, {status}), ...rest };
  }
  return { ...state, ...action.data };
};

export default transactionReducer;

