/**
 * Reducer for Leads
 */
import { replaceArrayItemsWithAnotherArrayById } from '../../utils';

const {
  LEADS_FETCH,
  LEAD_ADD,
  LEAD_ASSIGN_SALES,
  LEADS_SALES_AGENTS,
  RESET_LEADS_REDUCER,
} = require('store/actions/actionTypes');

const initialState = {
  leads: [],
  salesAgents: [],
  allSalesAgents: [],
  isLoading: false,
  hasUpdate: false,
  isError: true,
  errorMessage: '',
  page: 1,
  totalPages: 1,
  isLeadsLoading: false,
};

const leadsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LEADS_FETCH:
      return { ...state, ...action.data };
    case LEAD_ADD:
      return { ...state, ...action.data };
    case LEAD_ASSIGN_SALES:
      return assignSalesAgent(state, action);
    case LEADS_SALES_AGENTS:
      return { ...state, ...action.data };
    case RESET_LEADS_REDUCER:
      return { ...initialState, salesAgents: state.salesAgents };
    default:
      return state;
  }
};

const assignSalesAgent = (state, action) => {
  const { assignLead, ...rest } = action.data;
  if (assignLead) {
    const { customers } = assignLead;
    return {
      ...state,
      ...rest,
      leads: replaceArrayItemsWithAnotherArrayById(state.leads, customers)
    };
  } else {
    return {
      ...state,
      ...rest
    };
  }
};

export default leadsReducer;
