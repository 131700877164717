import React from 'react';

import {
  Grid,
  Button,
} from '@material-ui/core';

import illustration1 from '../../assets/images/illustrations/pack4/505.svg';
import {Link} from 'react-router-dom'

export default function LivePreviewExample() {
  return (
    <>
      <div className="app-wrapper bg-white">
        <div className="app-main">
          <div className="app-content p-0">
            <div className="app-inner-content-layout--main">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content">
                  <div className="hero-wrapper bg-composed-wrapper min-vh-100">
                    <div className="flex-grow-1 w-100 d-flex align-items-center">
                      <Grid
                        item
                        lg={6}
                        md={9}
                        className="px-4 px-lg-0 mx-auto text-center text-black">
                        <img
                          src={illustration1}
                          className="w-50 mx-auto d-block my-5 img-fluid"
                          alt="..."
                        />

                        <h3 className="font-size-xxl line-height-sm font-weight-light d-block px-3 mb-3 text-black-50">
                          You don't have permissions to access this resource.
                        </h3>
                        <Grid container spacing={0} justify='center'>
                          <Grid
                            item
                            sm={12}
                            md={4}
                            className="mt-4 mt-lg-3 px-2">
                            <Link
                              className="btn-wrapper--label"
                              style={{ color: 'white' }}
                              to="/dashboard">
                              <Button
                                className="d-block w-100 btn-first"
                                size="large">
                                <span className="btn-wrapper--label">Back To Dashboard</span>
                              </Button>
                            </Link>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
