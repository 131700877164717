import { useCallback, useEffect } from 'react';

const events = [
  'load',
  'mousemove',
  'mousedown',
  'click',
  'scroll',
  'keypress'
];

const AppLogout = ({ children }) => {
  const logoutTimeKey = 'logoutTime';
  const timerDuration = 60 * 60 * 1000; // 20 minutes

  const resetTimer = useCallback(() => {
    const newLogoutTime = Date.now() + timerDuration;
    localStorage.setItem(logoutTimeKey, newLogoutTime);
  }, [logoutTimeKey, timerDuration]);

  const checkLogout = useCallback(() => {
    const logoutTime = localStorage.getItem(logoutTimeKey);
    if (logoutTime && Date.now() >= logoutTime) {
      logoutAction();
    }
  }, [logoutTimeKey]);

  const logoutAction = () => {
    localStorage.clear();
    window.location.pathname = '/';
  };

  useEffect(() => {
    const handleActivity = () => {
      resetTimer();
      checkLogout();
    };

    Object.values(events).forEach((item) => {
      window.addEventListener(item, handleActivity);
    });

    const interval = setInterval(checkLogout, 1000); // Check every second

    return () => {
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, handleActivity);
      });
      clearInterval(interval);
    };
  }, [resetTimer, checkLogout]);

  return children;
};

export default AppLogout;
