import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { AnimatePresence, motion } from 'framer-motion';
import { GridLoader } from 'react-spinners';

import { Sidebar, Header, Footer } from '../../layout-components';
import { getMyProfile, getConfig } from '../../store/actions/dashboardActions';
import { fetchDocumentTitles } from '../../store/actions/documentActions';

import FormOverLayLoader from 'components/shared/FormOverLayLoader';

const LeftSidebar = (props) => {
  const {
    children,
    sidebarToggle,
    sidebarToggleMobile,
    sidebarFixed,
    headerFixed,
    headerSearchHover,
    headerDrawerToggle,
    footerFixed,
    contentBackground,
    getConfig,
    myProfile
  } = props;

  const { getMyProfile, fetchDocumentTitles } = props;

  useEffect(() => {
    getMyProfile();
    fetchDocumentTitles();
    getConfig();
  }, [getMyProfile]);

  return !myProfile.user || !myProfile.user._id ? (
    <AnimatePresence>
      <motion.div
        key="loading"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.4 }}>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <GridLoader color={'#395b77'} loading={true} />
          </div>
          <div className="text-muted font-size-xl text-center pt-3">
            Please wait while we load interface
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  ) : (
    <>
      <div
        className={clsx('app-wrapper', contentBackground, {
          'header-drawer-open': headerDrawerToggle,
          'app-sidebar-collapsed': sidebarToggle,
          'app-sidebar-mobile-open': sidebarToggleMobile,
          'app-sidebar-fixed': sidebarFixed,
          'app-header-fixed': headerFixed,
          'app-footer-fixed': footerFixed,
          'search-wrapper-open': headerSearchHover
        })}>
        <div>
          <Sidebar />
        </div>
        <div className="app-main">
          <Header />
          <div className="app-content">
            <div className="app-content--inner">
              <div className="app-content--inner__wrapper">{children}</div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

LeftSidebar.propTypes = {
  children: PropTypes.node
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarFixed: state.ThemeOptions.sidebarFixed,
  headerFixed: state.ThemeOptions.headerFixed,
  headerSearchHover: state.ThemeOptions.headerSearchHover,
  headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,

  footerFixed: state.ThemeOptions.footerFixed,
  contentBackground: state.ThemeOptions.contentBackground,
  myProfile: state.dashboardReducer
});

const mapDispatchToProps = {
  getMyProfile,
  fetchDocumentTitles,
  getConfig
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftSidebar);
