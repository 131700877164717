/**
 * Reduer for email templates
 */
 import { cloneDeep } from 'lodash'
import {
  GET_EMAIL_TEMPLATES,
  FETCH_EMAIL_TEMPLATES,
  FETCH_CUSTOM_EMAIL_TEMPLATES,
  FETCH_EMAIL_TEMPLATE_DETAIL,
  UPDATE_EMAIL_TEMPLATE_DETAIL,

  FETCH_CAMPAIGN_EMAIL_TEMPLATES,
  FETCH_CAMPAIGN_EMAIL_TEMPLATE_DETAIL,
  DELETE_CAMPAIGN_EMAIL_TEMPLATE,
  SET_CAMPAIGN_TMPL_OBJ,
  RESET_CAMPAIGN_TMPL_OBJ
} from '../actions/actionTypes';

const initialState = {
  page: 1,
  totalPages: 1,
  limit: 10,
  campaignTmplObj:{},
  campaignEmailTemplates: [],
  isLoading:false
};
const campaignEmailTemplateReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EMAIL_TEMPLATES:
      return state;
    case FETCH_CAMPAIGN_EMAIL_TEMPLATES:
      return { ...state, ...action.data };
    case FETCH_CUSTOM_EMAIL_TEMPLATES:
      return { ...state, ...action.data };
    case FETCH_CAMPAIGN_EMAIL_TEMPLATE_DETAIL:
      return { ...state, ...action.data };
    case UPDATE_EMAIL_TEMPLATE_DETAIL:
      return { ...state, ...action.data };
    case DELETE_CAMPAIGN_EMAIL_TEMPLATE:
      return { ...state, ...action.data };

      case SET_CAMPAIGN_TMPL_OBJ:
        let actionObj = cloneDeep(action.data)
        return {
          ...state,
          campaignTmplObj: {
            ...state.campaignTmplObj,
            ...actionObj
          } 
        }  
        case RESET_CAMPAIGN_TMPL_OBJ:
          return {
            ...state,
            campaignTmplObj: { } 
          }
    default:
      return state;
  }
};

export default campaignEmailTemplateReducer;
